export const isWindowDefined = () => typeof window !== 'undefined'

type GetLocalStorageItemOrDefaultFn = <T>(item: string, defaultVal: T) => T | string

export const getLocalStorageItemOrDefault: GetLocalStorageItemOrDefaultFn = (item, defaultVal) => {
  if (isWindowDefined()) {
    let localItem
    try {
      localItem = window.localStorage.getItem(item)
    } catch (_error) {
      return defaultVal
    }
    if (localItem) {
      try {
        return JSON.parse(localItem)
      } catch (_error) {
        return localItem
      }
    }
    return defaultVal
  }
  return defaultVal
}

export const setLocalStorageItem = (key: string, value: unknown): boolean => {
  if (!isWindowDefined()) {
    return false
  }

  try {
    const valueToStore = typeof value === 'object' && value !== null ? JSON.stringify(value) : String(value)
    window.localStorage.setItem(key, valueToStore)
    return true
  } catch (_error) {
    return false
  }
}

export const removeLocalStorageItem = (key: string): boolean => {
  if (!isWindowDefined()) {
    return false
  }

  try {
    window.localStorage.removeItem(key)
    return true
  } catch (_error) {
    return false
  }
}

export const getYesterday = () => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  return yesterday
}

export const getYesterdayTimestamp = () => getYesterday().getTime()

export const redirectToUrl = (url: string) => {
  if (isWindowDefined()) {
    window.location.assign(url)
    return true
  }
  return false
}

export const createQueryString = (name: string, value: string, searchParams: URLSearchParams) => {
  const params = new URLSearchParams(searchParams)
  params.set(name, encodeURIComponent(value))
  return params.toString()
}

type AppendSearchParamsFn = (name: string, value: string, params: URLSearchParams, isDelete?: boolean) => string

export const appendSearchParams: AppendSearchParamsFn = (name, value, params, isDelete) => {
  const searchParams = new URLSearchParams(params)
  if (isDelete) {
    searchParams.delete(name, value)
  } else {
    searchParams.append(name, value)
  }
  return searchParams.toString()
}

export const addArrayToSearchParams = (name: string, values: string[], params?: URLSearchParams) => {
  const searchParams = new URLSearchParams(params)
  values.forEach((it) => searchParams.append(name, it))
  return searchParams
}

/**
 * Retrieves browser cookies as an object with key-value pairs.
 *
 * @returns An object containing the cookies as key-value pairs.
 */
export const getBrowserCookies = () => {
  if (typeof document === 'undefined' || !isWindowDefined()) {
    return {}
  }
  // Split `document.cookie` into individual cookies
  const cookies = document.cookie ? document.cookie.split('; ') : []
  return cookies.reduce<Record<string, string>>((acc, cookie) => {
    const [name, ...value] = cookie.split('=')
    // Join the rest of the parts to handle cases where `=` is part of the cookie value
    const cookieValue = value.join('=')
    acc[name] = decodeURIComponent(cookieValue)
    return acc
  }, {})
}
