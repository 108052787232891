import type { IconComponentType } from '../types'

const WarningIcon = ({ size, className }: IconComponentType) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>warning icon</title>
    <path
      d="M1.86548 20.5L12 3.00006L22.1346 20.5H1.86548ZM12 17.8077C12.2289 17.8077 12.4207 17.7303 12.5755 17.5755C12.7303 17.4207 12.8077 17.2288 12.8077 17C12.8077 16.7711 12.7303 16.5793 12.5755 16.4245C12.4207 16.2697 12.2289 16.1923 12 16.1923C11.7712 16.1923 11.5794 16.2697 11.4246 16.4245C11.2698 16.5793 11.1924 16.7711 11.1924 17C11.1924 17.2288 11.2698 17.4207 11.4246 17.5755C11.5794 17.7303 11.7712 17.8077 12 17.8077ZM11.2501 15.1923H12.75V10.1923H11.2501V15.1923Z"
      fill="currentColor"
    />
  </svg>
)

export default WarningIcon
