import type { IconComponentType } from '../types'

const CloseIcon = ({ size, className = '' }: IconComponentType) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>close icon</title>
    <path
      d="M6.39999 18.6538L5.34616 17.6L10.9462 12L5.34616 6.40002L6.39999 5.34619L12 10.9462L17.6 5.34619L18.6538 6.40002L13.0538 12L18.6538 17.6L17.6 18.6538L12 13.0538L6.39999 18.6538Z"
      fill="currentColor"
    />
  </svg>
)

export default CloseIcon
