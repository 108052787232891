import type { Metadata } from 'next'
import type { StaticImageData } from 'next/image'

// Returns public path depending on the environment
export const getPublicPath = (file: string) => `${process.env.NEXT_PUBLIC_STATIC_FOLDER}${file}`

// Workaround because https://github.com/vercel/next.js/pull/53582
export const getWithBasePath = (path: string) => `${process.env.NEXT_PUBLIC_BASE_PATH ?? ''}${path}`

export const getUrl = (path: string) => `${process.env.NEXT_PUBLIC_BASE_URL ?? ''}${getWithBasePath(path)}`

const getBaseUrl = () => process.env.NEXT_PUBLIC_BASE_URL || 'https://www.gartner.com'

const getCanonical = (isSeo: boolean, url: string, canonical?: string) => {
  const baseUrl = getBaseUrl()
  if (isSeo) {
    return canonical || `${baseUrl}${url}`
  }

  return null
}

export const getBaseMetaData = ({
  title = 'Gartner Peer Community | Drive Technology and Business Decisions with Peer-driven Insights',
  description = 'Gartner Peer Community is a peer-driven platform where enterprise leaders can join engaging conversations, ask or answer polls, read insightful reports and connect with peers.',
  image,
  url,
  isSeo = false,
  canonicalUrl,
}: {
  title?: string
  description?: string
  image?: StaticImageData | string
  url: string
  isSeo?: boolean
  canonicalUrl?: string
}): Metadata => {
  // INDEX_ENABLED is an environment variable that allows us to control whether pages
  // should be indexed by search engines in non-production environments. Indexing is
  // only enabled in the prod, int, and qa envs.
  const isIndexEnabled = process.env.INDEX_ENABLED === 'true'

  const index = isIndexEnabled && isSeo
  const follow = isIndexEnabled

  const robots = { index, follow }
  const ogImages = image ? { images: ((image as StaticImageData)?.src || image) as string } : {}
  const baseUrl = getBaseUrl()
  const canonical = getCanonical(isSeo, url, canonicalUrl)
  return {
    metadataBase: new URL(baseUrl),
    title,
    description,
    openGraph: { type: 'website', url, title, description, ...ogImages },
    twitter: {
      card: 'summary_large_image',
      site: '@Gartner_Peer',
      title,
      description,
      ...ogImages,
    },
    icons: [
      { rel: 'icon', url: getPublicPath('img/favicon.png'), sizes: '16x16' },
      {
        rel: 'icon',
        url: getPublicPath('img/favicon-36x36.png'),
        sizes: '36x36',
      },
    ],
    robots: { ...robots, googleBot: { ...robots } },
    verification: { google: process.env.NEXT_GOOGLE_VERIFICATION_ID },
    formatDetection: {
      telephone: false,
      date: false,
      address: false,
      email: false,
    },
    alternates: { canonical },
  }
}
