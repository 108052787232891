class APIError extends Error {
  readonly status: number
  readonly message: string

  constructor(status: number, message?: string) {
    super(`API Error: ${status}`)

    this.status = status
    this.message = message ?? ''
  }
}

const isAPIError = (error: unknown): error is APIError => error instanceof APIError && 'status' in error

export { APIError, isAPIError }
