import {
  ACTIVITY_TYPE_KEY,
  ACTIVITY_TYPE_MAP,
  ACTIVITY_TYPE_OTHERS,
  type ActivityValueType,
  DEFAULT_AUTH_PARAMS,
  HEADER_TOKEN,
  LOGIN_REDIRECT_KEY,
  PREFER_STEP_MAP,
  REDIRECT_PARAMS,
  STORAGE_AUTH_ITEMS,
  type StepType,
  TOKEN_TYPE,
  UNAUTHORIZED,
} from '@/services/auth/constants'
import {
  getBrowserCookies,
  isWindowDefined,
  redirectToUrl,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@/util/browser'
import { arrayBufferToBase64Url, generateQueryParams, generateRandomStr } from '@/util/strings'

import type { ReadonlyURLSearchParams } from 'next/navigation'
import { deleteToken } from '../cookies/cookiesServerActions'
import { APIError } from '../error'

/**
 * Logs out the user from the Gcom Identity Provider (IdP) by making a POST request to the logout endpoint.
 * This function is intended to be called from the client side only.
 *
 * @throws {APIError} If the logout request fails.
 */
const gcomLogout = async () => {
  // Client-side only logout
  if (isWindowDefined()) {
    const response = await fetch(process.env.NEXT_PUBLIC_LOGOUT_ENDPOINT, {
      method: 'POST',
      credentials: 'include',
    })

    if (!response.ok) {
      const text = await response.text()
      throw new APIError(response.status, text)
    }
  }
}

/**
 * Generates a code challenge from a code verifier.
 *
 * This function is used in the OAuth PKCE (Proof Key for Code Exchange) flow.
 *
 * @param code - The code verifier string.
 * @returns A promise that resolves to the code challenge string.
 */
const generateCodeChallenge = async (code: string): Promise<string> => {
  const encoder = new TextEncoder()
  const data = encoder.encode(code) // Encode the input code as a Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', data) // Generate a SHA-256 hash of the encoded data
  const code_challenge = arrayBufferToBase64Url(hashBuffer)
  return code_challenge
}

export const buildAuthorizeParams = async (step: StepType) => {
  const state = btoa(generateRandomStr())
  const nonce = btoa(generateRandomStr())
  const code = generateRandomStr()
  const code_challenge = await generateCodeChallenge(code)
  const queryObj = { ...DEFAULT_AUTH_PARAMS, step, state, nonce, code_challenge, code }
  return queryObj
}

const getRedirectUri = (regActivity: string) => {
  if (isWindowDefined()) {
    const base = process.env.NEXT_PUBLIC_BASE_URL || window.location.origin
    return `${base}/peer-community/callback?regActivity=${regActivity}`
  }
}

const isUnauthorized = () => !getBrowserCookies()[HEADER_TOKEN] || getBrowserCookies()[UNAUTHORIZED] === 'true'

export const redirectToAuthorizeUrl = async (step: StepType | undefined, regActivity: ActivityValueType) => {
  if (!!step && step.length > 0 && isUnauthorized()) {
    const { code, ...params } = await buildAuthorizeParams(step)
    const redirect_uri = getRedirectUri(regActivity)
    const completeParams = { ...params, redirect_uri }
    const queryStr = generateQueryParams(completeParams)
    const authUrl = `${process.env.NEXT_PUBLIC_AUTH_ENDPOINT}${queryStr}`
    const state = { state: params.state, code_verifier: code, nonceIn: params.nonce }
    STORAGE_AUTH_ITEMS.forEach((it) => removeLocalStorageItem(it))
    setLocalStorageItem('state', state)
    redirectToUrl(authUrl)
  } else {
    removeLocalStorageItem('state')
    removeLocalStorageItem(LOGIN_REDIRECT_KEY)
  }
}

export const getActivityFromUrl = (url = '/'): ActivityValueType =>
  ACTIVITY_TYPE_MAP[ACTIVITY_TYPE_KEY.find((it) => url.includes(`/${it}/`)) || ACTIVITY_TYPE_OTHERS]

export const getStepFromParams = (params: ReadonlyURLSearchParams): StepType | undefined => {
  const [key] = REDIRECT_PARAMS.filter((p) => params.get(p) === 'true')
  const step = PREFER_STEP_MAP[key]
  return step
}

const authorizeWithStep = async (step: StepType, url: string) => {
  const activity = getActivityFromUrl(url)
  await redirectToAuthorizeUrl(step, activity)
}

export const authorizeByUrlParams = async (params: ReadonlyURLSearchParams, url: string) => {
  const step = getStepFromParams(params)
  step && (await authorizeWithStep(step, url))
}

type AuthWithAnyType = (
  params: ReadonlyURLSearchParams,
  url: string,
  step?: StepType,
  authWithStepFn?: typeof authorizeWithStep,
  authWithoutStepFn?: typeof authorizeByUrlParams
) => void

export const authorizeWithAny: AuthWithAnyType = async (
  params,
  url,
  step,
  authWithStepFn = authorizeWithStep,
  authWithoutStepFn = authorizeByUrlParams
) => {
  const hasRedirectParams = REDIRECT_PARAMS.some((it) => params.has(it))
  const hasRedirectParamsOrStep = hasRedirectParams || !!step
  if (hasRedirectParamsOrStep) {
    // Adhoc Fix for https://gartner.atlassian.net/jira/software/c/projects/PP/boards/2083?selectedIssue=PP-11271
    // TODO: encapsulate this into a function and review generateQueryParams and removeEntriesByKey
    const cleanedParams = new URLSearchParams(params.toString())

    REDIRECT_PARAMS.forEach((param) => {
      cleanedParams.delete(param)
    })

    const redirectUrl = url + (cleanedParams.toString() ? `?${cleanedParams.toString()}` : '')
    setLocalStorageItem(LOGIN_REDIRECT_KEY, redirectUrl)

    if (step) {
      await authWithStepFn(step, url)
    } else {
      await authWithoutStepFn(params, url)
    }
  } else {
    if (!isUnauthorized()) {
      removeLocalStorageItem(LOGIN_REDIRECT_KEY)
    }
  }
}

export const cleanLocalStorage = () => {
  removeLocalStorageItem(HEADER_TOKEN)
  removeLocalStorageItem(TOKEN_TYPE)
}

export const clientLogout = async () => {
  await gcomLogout()
  await deleteToken()
  cleanLocalStorage()
}
