import Typography from '@/components/core/Typography'
import CloseIcon from '@/components/icons/gravity/CloseIcon'
import ErrorIcon from '@/components/icons/gravity/ErrorIcon'
import InformationIcon from '@/components/icons/gravity/InformationIcon'
import LightbulbIcon from '@/components/icons/gravity/LightbulbIcon'
import SuccessIcon from '@/components/icons/gravity/SuccessIcon'
import WarningIcon from '@/components/icons/gravity/WarningIcon'
import clsx from 'clsx'
import { isValidElement } from 'react'
import { type Renderable, toast } from 'react-hot-toast'
import styles from './Toast.module.scss'

export type ToastProps = {
  variant?: 'success' | 'error' | 'warning' | 'info' | 'help'
  content: Renderable
  position?: 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right'
  dismissible?: boolean
  icon?: Renderable
}

const icons = new Map(
  Object.entries({
    success: <SuccessIcon size={24} className={styles.icon} />,
    error: <ErrorIcon size={24} className={styles.icon} />,
    warning: <WarningIcon size={24} className={styles.icon} />,
    info: <InformationIcon size={24} className={styles.icon} />,
    help: <LightbulbIcon size={24} className={styles.icon} />,
  })
)

export const showToast = ({
  variant = 'info',
  position = 'bottom-center',
  content,
  dismissible = true,
  icon,
}: ToastProps) =>
  toast.custom(
    (scopeToast) => (
      <div
        className={clsx(styles.toast, styles[variant], {
          [styles.animate_enter]: scopeToast.visible,
          [styles.animate_leave]: !scopeToast.visible,
        })}
      >
        {scopeToast.icon}
        <div className={styles.content}>
          {isValidElement(content) ? content : <Typography variant="h5">{content}</Typography>}
        </div>
        {dismissible && (
          <button className={styles.button} onClick={() => toast.remove(scopeToast.id)} type="button">
            <CloseIcon size={24} />
          </button>
        )}
      </div>
    ),
    {
      icon: icon && icons.get(variant),
      position,
    }
  )
