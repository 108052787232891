import { getUrl } from './next'

export const getLandingLoggedInUrl = (url: string): string => {
  const paths = ['/post/', '/poll/', '/oneminuteinsights/', '/ambassadors', '/discussions']
  if (paths.some((path) => url.includes(path))) {
    return url
  }

  return getUrl('/feed')
}
