'use client'

import type { GAEvent } from '@/types/common'
import { createContext, type PropsWithChildren, useContext } from 'react'

const cleanObject = (obj: Partial<GAEvent> = {}): GAEvent =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (value) {
      acc[key as keyof GAEvent] = value
    }
    return acc
  }, {} as GAEvent)

const EventContext = createContext<GAEvent>({
  component: undefined,
  pk: undefined,
  object: undefined,
  name: undefined,
  platform: undefined,
})

const EventContextProvider = ({ value, children }: PropsWithChildren<{ value: GAEvent }>) => (
  <EventContext.Provider value={value}>{children}</EventContext.Provider>
)

const useEventContext = (fallbackEvent: GAEvent = {}) => {
  const fromContext = useContext(EventContext)
  const getEvent = (gaEvent: GAEvent): GAEvent => ({
    ...cleanObject(fallbackEvent),
    ...cleanObject(fromContext),
    ...cleanObject(gaEvent),
  })
  const sendEvent = (gaEvent: GAEvent = {}) => {
    const event = getEvent(gaEvent)
    window.gtag(event.name, event)
  }
  return {
    sendEvent,
    getEventProps: (gaEvent: GAEvent = {}) => {
      const props = getEvent(gaEvent)
      return {
        'data-evco': props.component,
        'data-evob': props.object,
        'data-evpk': props.pk,
        'data-evna': props.name,
        'data-evpa': props.platform,
      }
    },
  }
}

export { EventContext, EventContextProvider, useEventContext }
