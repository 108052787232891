const CHARSET = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

export const generateRandomStr = (length = 43, wishlist = CHARSET) =>
  Array.from(crypto.getRandomValues(new Uint32Array(length)))
    .map((x) => wishlist[x % wishlist.length])
    .join('')

export const truncateChars = (input = '', noOfChars = 10) =>
  input.length > noOfChars ? `${input.slice(0, noOfChars)} ...` : input || ''

export const cleanHtmlTags = (str = '') => str?.replace(/(<([^>]+)>)/gi, '') || ''

export const cleanTextDoubleQuotes = (str = '') => cleanHtmlTags(str)?.replace(/(")/gi, "'")

export const cleanSpacesAndLineBreaks = (str = '') => str?.replace(/[\r\n|\r|\n|\s\s]+/gi, ' ')

export const cleanTextAll = (str = '') => cleanSpacesAndLineBreaks(cleanTextDoubleQuotes(cleanHtmlTags(str)))

export const simpleNumber = (num = 0) => {
  if (num <= 999) {
    return `${num}`
  }
  return `${(num / 1_000).toFixed(1)}k`.replace('.0', '')
}

/**
 * Pluralizes a string based on the provided count.
 *
 * @param num - The count to determine whether to use the singular or plural form.
 * @param singular - The singular form of the string.
 * @param [plural=`${singular}s`] - The plural form of the string.
 * @returns - The singular or plural form of the string.
 *
 * @example
 * const result = pluralize(3, 'apple'); // Result: "apples"
 * const result = pluralize(3, 'child', 'children'); // Result: "children"
 */
export const pluralize = (num: number, singular: string, plural = `${singular}s`) =>
  singular.length > 0 ? ([1, -1].includes(num) && singular) || plural : ''

export const cleanComment = (str = '') =>
  cleanHtmlTags(str)
    .replace(/(\n){2,}/gi, '<br><br>')
    .replace(/\\/gi, '/')
    .replace(/\n/gi, '<br>')

export const generateQueryParams = (params: object = {}, prefix = '?') => {
  let result = ''
  if (typeof params === 'object' && !Array.isArray(params)) {
    const queryParams = Object.entries(params)
      .filter(([, value]) => typeof value !== 'undefined' && value !== null)
      .map(([key, value]) => [encodeURIComponent(key), encodeURIComponent(String(value))])
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    if (queryParams) {
      result = `${prefix}${queryParams}`
    }
  }
  return result
}

// https://base64.guru/standards/base64url
export const toBase64url = (url = '') => url.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')

/**
 * Converts an ArrayBuffer to a Base64 URL-safe string.
 *
 * @param buffer - The ArrayBuffer to convert.
 * @returns The Base64 URL-safe string representation of the buffer.
 */
export const arrayBufferToBase64Url = (buffer: ArrayBuffer): string => {
  const uint8Array = new Uint8Array(buffer)
  const base64String = btoa(String.fromCharCode(...uint8Array))
  return toBase64url(base64String)
}

export const truncateWords = (input: string, limit: number): string => {
  const regex = new RegExp(`^.{${limit}}.*?\\b`)
  const [result] = input?.match(regex) || []
  const needsEllipsis = result && result.length < input.length
  return result ? `${result.trim()}${needsEllipsis ? '...' : ''}` : input || ''
}

/**
 * Converts an array of strings into a formatted string using Intl.ListFormat.
 *
 * @param list - The array of strings to be formatted.
 * @returns The formatted string.
 *
 * @example
 * const formattedString = listToString(['apple', 'orange', 'banana']);
 * // Result: "apple, orange, and banana"
 */
export const listToString = (list: string[]): string => {
  if (!list) {
    return ''
  }
  const formatter = new Intl.ListFormat('en-GB', { style: 'long', type: 'conjunction' })
  return formatter.format(list.map((item) => item.trim()))
}

/**
 * Limits the number of elements in an array and adds a "more" message if needed.
 *
 * @param items - The array of strings to limit.
 * @param number - The maximum number of elements to include in the result before adding "more"
 * @returns The limited array with an optional "more" message.
 *
 * @example
 * const result = limitDepartments(['1', '2', '3', '4']);
 * // Returns ['1', '2', '3', '4']
 *
 * @example
 * const result = limitDepartments(['1', '2', '3', '4'], 4);
 * // Returns ['1', '2', '3', '4']
 *
 * @example
 * const result = limitDepartments(['1', '2', '3', '4'], 2);
 * // Returns ['1', '3 more']
 */
export const limitList = (items: string[] = [], limit = 999_999): string[] => {
  if (limit > 0 && limit < items.length) {
    const lastItem = `${items.length - limit + 1} more`
    return [...items.slice(0, limit - 1), lastItem]
  }
  return items
}

/**
 * Type guard that checks if the provided value is of type string.
 *
 * @param value - The value to check.
 * @returns - Returns true if the value is of type string, otherwise returns false.
 */
export const isString = (value: unknown): value is string => typeof value === 'string'

/**
 * Escapes special characters in a string to make it safe for use in a regular expression.
 *
 * This function replaces characters that have special meaning in regular expressions
 * (such as `.`, `*`, `?`, `+`, `|`, etc.) with their escaped equivalents.
 *
 * Example:
 * ```
 * const input = "hello.world";
 * const escaped = escapeRegExp(input);
 * console.log(escaped); // Outputs: "hello\.world"
 * ```
 *
 * @param {string} value - The input string containing characters that need to be escaped.
 * @returns A new string where all special regex characters are escaped.
 */
export const escapeRegEx = (value: string): string => value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
