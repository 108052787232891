import type { GravityComponentType } from '@/components/gravityTypes'
import type { PolymorphicComponentPropWithRef, PolymorphicRef } from '@/types/common'
import clsx from 'clsx'
import React from 'react'
import styles from './Button.module.scss'

// https://blog.logrocket.com/build-strongly-typed-polymorphic-components-react-typescript/
type StateType = 'disabled' | 'active' | 'hover' | 'normal'
type PlainButtonProps = { state?: StateType } & GravityComponentType
type BtnProps<T extends React.ElementType> = PolymorphicComponentPropWithRef<T, PlainButtonProps>

type ButtonComponent = <T extends React.ElementType = 'button'>(props: BtnProps<T>) => React.ReactNode | null

const Button: ButtonComponent = <T extends React.ElementType = 'button'>(
  { as, variant = 'primary', size = 'regular', state = 'normal', className, ...props }: BtnProps<T>,
  ref?: PolymorphicRef<T>
) => {
  const Component = as || 'button'
  return <Component {...props} className={clsx(styles[state], styles[size], styles[variant], className)} ref={ref} />
}

const ButtonWithRef: ButtonComponent = React.forwardRef(Button)
export type ButtonProps = React.ComponentPropsWithRef<typeof ButtonWithRef>
// Necessary to avoid errors using next/link
export default ButtonWithRef
